import AppBar from '@material-ui/core/AppBar';
import { Toolbar, Typography} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import InputBoard from './components/inputBoard.js'
import Viewer from './components/viewer.js';

import {
	BrowserRouter as Router,
	Route,
  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	app:{
		textAlign: 'center'
	},
	footer: {
		padding: theme.spacing(2),
		height: 80
	},
	main: {
		marginTop: 10,
		marginLeft: 0,
		marginRight: 0,
		paddingLeft: 0,
		paddingRight: 0
	},
	header: {
		height: 60,
	},
	toolbar: {
		width: '100%',
		backgroundColor: 'white',
		display: 'flex',
		position: 'fixed',
		margin: 0,
		height: 50,
		bottom: 0,
		zIndex: 999
	}
}));



const Copyright = () => {
	return (
		<Typography variant="body2" align="center" color="textSecondary">
			{"Copyright © "}
			<Link color="inherit" href="https://github.com/barisu">
				barisu
      </Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

function App() {
	const classes = useStyles();

	return (
		<div className={classes.app}>
			<AppBar className={classes.header} position="sticky" >
				<Toolbar>
					<Typography variant="h5" align="left" >
						Scheduler
            		</Typography>
				</Toolbar>
			</AppBar>
			<Router>
				<Route  path="/post/:code" >
					<InputBoard  className={classes.main}/>
				</Route>
				<Route path="/schedules/:code">
					<Viewer />
				</Route>
			</Router>
			<footer className={classes.footer} >
				<Copyright />
			</footer>
		</div>
	);
}



export default App;
