import { useState } from 'react';
import { Container, Typography, Button, Box, makeStyles, Card } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import * as config from '../config';
import { Link } from 'react-router-dom';
import * as holiday from '@holiday-jp/holiday_jp';


const useStyles = makeStyles((theme) => ({
    attendancesByDay: {
        marginTop: 7,
        marginBottom: 7,
        padding: 20,
        borderStyle: 'solid',
        borderColor: '#6091d3',
        borderWidth: '4px',
        borderRadius: '10px',
        textAlign: 'left'
    },
    attendanceByDayCard: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'flex-end',
        padding: 10
    },
    attendancesByParticipantCard: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        minHeight: '30px',
        paddingTop: 0,
        paddingBottom: 0
    },
    attendancesByParticipant: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
    },
    attendanceBox: {
        width: '25px',
        height: '20px',
        textAlign: 'center',
        fontSize: 20,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 1,
        marginLeft: 1,
        borderRadius: 5,
        color: 'gray'
    },
    participantName: {
        textAlign: 'left',
        paddingLeft: '5%',
        paddingRight: '5%'
    },
}));

const Viewer = (props) => {
    const { code } = useParams();
    const [attendances, setAttendances] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [startDt, setStartDt] = useState();
    const [endDt, setEndDt] = useState();

    const initAttendances = () => {
        setIsLoaded(true);
        fetch(`${config.api_server}/${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            (result) => {
                if (result.ok) {
                    return result.json();
                }
            },
            (error) => {
                console.log(error);
            }
        ).then(data => {
            setStartDt(moment(data.start_dt));
            setEndDt(moment(data.end_dt));
            setAttendances(data.participants);
        });
    }

    const linkToInput = () => {
        const event_form_path = `/post/${code}?startDate=${startDt.format('YYYY-MM-DD')}&endDate=${endDt.format('YYYY-MM-DD')}`;
        return (
        <Button>
            <Link to={event_form_path} >
                予定を入力
            </Link>
        </Button>
        )
    };

    if (isLoaded) {
        if (attendances !== undefined) {
            const under_adjustment_dates = Array(endDt.diff(startDt, 'days') + 1).fill(0).map((val, index) => {
                const date = startDt.clone().add(index, 'days');
                return attendances.filter(item => date.isSame(moment(item.attendance_dt), 'day'))
            });
            const attendances_by_days = under_adjustment_dates.map((attendances_by_day, index) => {
                return (
                    <AttendanceByDay attendances={attendances_by_day} date={startDt.clone().add(index, 'days')} key={index} />
                )
            });

            return (
                <Container>
                    {linkToInput()}
                    {attendances_by_days}
                </Container>
            )
        } else {
            return (
                <Container>
                    Now Loading...
                </Container>
            )
        }
    } else {
        initAttendances();
        return (
            <Container>
                Now Loading...
            </Container>
        )
    }

}

const AttendanceByDay = (props) => {
    const classes = useStyles();
    const attendances = props.attendances;
    const date = props.date;
    const participants = Array(...(new Set(attendances.map(attendance => attendance.name))));
    const hiduke = [ "日", "月", "火", "水", "木", "金", "土" ][date.day()];
    let day_color = 'black';
    if (date.day() === 0 || holiday.isHoliday(date)) {
        day_color = '#D33E31';
    } else if(date.day() === 6) {
        day_color = '#033EA1';
    }
    const participants_bar = participants.map(name => {
        const attendances_by_participants = attendances.filter(attendance => date.isSame(moment(attendance.attendance_dt), 'day') && attendance.name === name).map(attendance => {
            if (attendance.attendance_flg == null) {
                return (
                    <Box key={`${name}_${attendance.attendance_dt}`} style={{ backgroundColor: '#ffe887' }} className={classes.attendanceBox}>
                        <Typography>{moment(attendance.attendance_dt).hour()}</Typography>
                    </Box>
                )
            } else if (attendance.attendance_flg === 1) {
                return (
                    <Box key={`${name}_${attendance.attendance_dt}`} style={{ backgroundColor: '#80c2fc' }} className={classes.attendanceBox}>
                        <Typography>{moment(attendance.attendance_dt).hour()}</Typography>
                    </Box>
                )
            } else if (attendance.attendance_flg === 0) {
                return (
                    <Box key={`${name}_${attendance.attendance_dt}`} style={{ backgroundColor: '#cbcacb' }} className={classes.attendanceBox}>
                        <Typography>{moment(attendance.attendance_dt).hour()}</Typography>
                    </Box>
                )
            }
        });
        return (
            <Card className={classes.attendancesByParticipantCard} key={`card_${name}_${date}`} >
                <Typography color='textPrimary' className={classes.participant_name}>{name}</Typography>
                <Container key={`${name}_${date}`} className={classes.attendancesByParticipant}>
                    {attendances_by_participants}
                </Container>
            </Card>
        )
    });
    return (
        <Box className={classes.attendancesByDay} key={`box_${date.format('YYYY-MM-DD')}`}>
            <Typography style={{color:day_color}}>{(new Date(date)).toLocaleDateString()} {hiduke}</Typography>
            <Card className={classes.attendanceByDayCard} key={date.format('YYYY-MM-DD')}>
                {participants_bar}
            </Card>
        </  Box>
    )

}



export default Viewer;

