import { useState } from 'react';
import { Container, Typography, Button, Box} from '@material-ui/core';
import * as holiday from '@holiday-jp/holiday_jp';
import moment from 'moment';
import DayScheduler from './byDayScheduler.js';
import * as deepcopy from 'deepcopy';
import { makeStyles } from "@material-ui/core/styles";
import * as config from '../config';
import { useParams,useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
  }
  

const useStyles = makeStyles((theme) => ({
	main: {
		marginTop: 10,
		marginLeft: 0,
		marginRight: 0,
		paddingLeft: 0,
		paddingRight: 0
	},
	sample: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: 10,
		paddingLeft: 10,

	},
	sampleBox: {
        fontSize: 20,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 1,
        marginLeft: 1,
        borderRadius: 10,
        color: 'gray'		
	},
	toolbar: {
		width: '100%',
		backgroundColor: 'white',
		display: 'flex',
		position: 'fixed',
		margin: 0,
		height: 50,
		bottom: 0,
		zIndex: 999
	}
}));

const isDayOff = (date) => {
	const dayOfTheWeek = date.getDay();
	if (dayOfTheWeek === 0 || dayOfTheWeek === 6 || holiday.isHoliday(date)) {
		return true;
	}
	else {
		let yesterday = moment(date).subtract(1, 'd');
		while (holiday.isHoliday(yesterday)) {
			if (yesterday.getDay() === 0) {
				return true;
			}
			yesterday = moment(yesterday).subtract(1, 'd');
		}
		return false;
	}
}


const InputBoard = (props) => {
	const { code } = useParams();
	const classes = useStyles();
	const [inputState,setInputState] = useState(true);
	let params = useQuery();
	
    let onlyDayOff = params.get('onlyDayOff');
    if (onlyDayOff === 'true') {
		onlyDayOff = true
    } else {
		onlyDayOff = false;
    }
	
	
	const iniTimes = function (params){
		let result = {};
        let workdayStartTime = params.get('workdayStartTime');
        let workdayEndTime = params.get('workdayEndTime');
        let dayOffStartTime = params.get('dayOffStartTime');
        let dayOffEndTime = params.get('dayOffEndtime');
		
		
        if (workdayStartTime == null) {
			workdayStartTime = '17:00:00';
        }
        if (workdayEndTime == null) {
			workdayEndTime = '23:00:00';
        }
        if (dayOffStartTime == null) {
			dayOffStartTime = '09:00:00';
        }
        if (dayOffEndTime == null) {
			dayOffEndTime = '23:00:00';
        }
		
		
		try {
			
			const startDate = new Date(params.get('startDate'));
			const endDate = new Date(params.get('endDate'));
			for(let dayCnt = 0; dayCnt <= (endDate - startDate) / (60 * 60 * 1000 * 24);dayCnt++){
				const day = moment(startDate).add(dayCnt, 'd').toDate();
				const workdayStartDatetime = new Date(day.toLocaleDateString() + ' ' + workdayStartTime);
				const workdayEndDatetime = new Date(day.toLocaleDateString() + ' ' + workdayEndTime);
				const dayOffStartDatetime = new Date(day.toLocaleDateString() + ' ' + dayOffStartTime);
				const dayOffEndDatetime = new Date(day.toLocaleDateString() + ' ' + dayOffEndTime);
                
				if (isDayOff(day)){
					for(let i = 0; i <= (dayOffEndDatetime - dayOffStartDatetime)/(1000 * 60 * 60); i++){
						result[moment(dayOffStartDatetime).add(i, 'h').toDate().toLocaleString()] = false;
					}
				} else if (!onlyDayOff) {
					for(let i = 0; i <= (workdayEndDatetime - workdayStartDatetime)/(1000 * 60 * 60); i++){
						result[moment(workdayStartDatetime).add(i, 'h').toDate().toLocaleString()] = false;
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
		return result;
	}(params);
	const [times, setTimes] = useState(iniTimes);
	
	const api = () => {
		let name = window.prompt('なまえをいれてね！','');
		if (!name){
			return;
		}
		let passcode = window.prompt('パスワードを入れてね！','');
		if (!passcode){
			return;
		}
		fetch(`${config.api_server}/`,{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
				},
			body: JSON.stringify({times: times,name : name, event_cd : code, password: passcode})
		}).then(
			(result) => {
				if (result.status === 200) {
					window.location.href = `/schedules/${code}`;
				}
			},
			(error) => {
				alert('頑張りましたがダメでした...');
			}
		)

	}

	const updateAttendance = (datetime,bool) => {
		const dt = new Date(datetime);
		const dtStr = dt.toLocaleString();
		if (bool !== times[dtStr]){
			times[dtStr] = bool;
			setTimes(deepcopy(times));
		}
	}
	const calenderElement = function (params) {
		try {
			const startDate = new Date(params.get('startDate'));
			const endDate = new Date(params.get('endDate'));
			const days = new Array((endDate - startDate) / (60 * 60 * 1000 * 24) + 1).fill()
				.map((_, index) => {
					const day = moment(startDate).add(index, 'd').toDate();
                    if (isDayOff(day)) {
                        return (<DayScheduler inputState={inputState} update={(datetime,bool) => updateAttendance(datetime,bool)} day={day} times={Object.entries(times).filter(keyVal => moment(new Date(keyVal[0])).isSame(day,'day'))} key={`days-${index}`}/>);
                    } else if (!onlyDayOff) {
                        return (<DayScheduler inputState={inputState} update={(datetime,bool) => updateAttendance(datetime,bool)} day={day} times={Object.entries(times).filter(keyVal => moment(new Date(keyVal[0])).isSame(day,'day'))} key={`days-${index}`}/>);
                    } else {
                        return null;
                    }
				}).filter(component => component != null);
			return days
		} catch (error) {
			console.log(error);
			return (<Typography>クエリ文字列に誤りがあります.<a href="/?startDate=2021-04-01&endDate=2021-05-01&workdayStartTime=18:00:00&workdayEndTime=23:00:00&dayOffStartTime=09:00:00&dayOFfEndTime=23:00:00">正しい例</a></Typography>);
		}
	};


	return (
		<Container className={classes.main}>
			<Container>
				{calenderElement(params)}
				<Button 
					variant="contained" 
					style={{color: 'white'}}
					color="primary" 
					className={classes.sampleBox}
					onClick={() => api()}
				>
					送信
				</Button>
			</Container>
			<Box className={classes.toolbar}>
				<Box className={classes.sample}>
					<Button onClick={() => {setInputState(false)}} className={classes.sampleBox} style={{backgroundColor: '#cbcacb'}} variant="outlined">欠席</Button>
					<Button onClick={() => {setInputState(null)}} className={classes.sampleBox} style={{backgroundColor: '#ffe887'}} variant="outlined">未定</Button>	
					<Button onClick={() => {setInputState(true)}} className={classes.sampleBox} style={{backgroundColor: '#80c2fc'}} variant="outlined">出席</Button>
				</Box>
			</Box>
		</Container>
	)
}


export default InputBoard;
